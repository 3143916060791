body {
  background-color: #f6f6ef;
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin: 0 24px;
  padding: 0 0 48px;
}

a {
  color: #222;
  text-decoration: none;
  text-decoration-color: #888;
}
a:hover {
  text-decoration-line: underline;
}

p {
  margin: 12px 0;
}

pre {
  margin: 24px 0;
  overflow-x: auto;
}

h1 {
  color: #999;
  font-size: 1em;
  font-weight: normal;
  margin: 36px 0 12px;
}
h1 a {
  margin: -6px;
  padding: 6px;
}

.item {
  margin-bottom: 12px;
}
.item a:visited {
  color: #888;
}

.host {
  color: #888;
}

.meta {
  color: #888;
  font-size: 16px;
  vertical-align: bottom;
}

.more a {
  margin: -6px;
  padding: 6px;
  text-decoration-line: underline;
}

.comment {
  border-top: 1px solid #ddd;
  font-size: 18px;
  margin: 12px 0;
  padding-top: 11px;
}

.comment .comment {
  margin-left: 24px;
}

@media only screen and (max-width: 800px) {
  .comment a {
    display: inline-block;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
  }
}

.text a {
  text-decoration-line: underline;
}
